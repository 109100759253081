export const formConstants = {
  CHASSIS_REQUEST: 'CHASSIS_REQUEST',
  CHASSIS_SUCCESS: 'CHASSIS_SUCCESS',
  CHASSIS_FAILURE: 'CHASSIS_FAILURE',

  TESTDRIVE_REQUEST: 'TESTDRIVE_REQUEST',
  TESTDRIVE_SUCCESS: 'TESTDRIVE_SUCCESS',
  TESTDRIVE_FAILURE: 'TESTDRIVE_FAILURE',

  COTIZACION_REQUEST: 'COTIZACION_REQUEST',
  COTIZACION_SUCCESS: 'COTIZACION_SUCCESS',
  COTIZACION_FAILURE: 'COTIZACION_FAILURE'
};