import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { Provider } from "react-redux"
import { store } from "../redux/_helpers"
import Transition from "../components/transition"
import Header from "../components/header"
import Footer from "../components/footer"
// Styles
import "typeface-montserrat"
import "../assets/scss/bsc.scss"
import { Children } from "react"

class Layout extends React.Component {


  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            chevrolet {
              menu(id: "TWVudToz") {
                id
                menuItems {
                  edges {
                    node {
                      id
                      menuItemId
                      url
                      label
                    }
                  }
                }
              }
            }
          }
        `}
        render={e => {
          const { menuItems } = e.chevrolet.menu
          const { children, location, data} = this.props
          const hasHeroBanner = data?.chevrolet?.page?.content.includes('class="hero-banner"');
          const innerContentClass = hasHeroBanner ? null : 'inner-content';
          

          return (
            <>
              <Provider store={store}>
               <div> <Header menuItems={menuItems} hasHeroBanner={hasHeroBanner} /> </div>
                <div>
                <Transition location={location} >
                    <main className={innerContentClass} >{children}</main>
                    <Footer />
                </Transition>
                </div>
              </Provider>

            </>
          )
        }}
      />
    )
  }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
