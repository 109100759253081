import { formConstants } from '../_constants'

function chassis(state = {}, action) {
  switch (action.type) {
    case formConstants.CHASSIS_SUCCESS:
      return action.data
    case formConstants.CHASSIS_FAILURE:
      return {}
    default:
      return state
  }
}

export {
  chassis
}
