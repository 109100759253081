import { combineReducers } from 'redux'

// import { authentication } from './authentication.reducer'
// import { registration } from './registration.reducer'
import { search } from './search.reducer'
import { chats, cotizaciones } from './dash.reducer'
import { chassis } from './chassis.reducer'
import { alert } from './alert.reducer'

const rootReducer = combineReducers({
  search,
  chats,
  chassis,
  cotizaciones,
  alert
});

export default rootReducer;