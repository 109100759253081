module.exports = {
  pathPrefix: "/",
  title: "Suzuki República Dominicana",
  titleShort: "suzuki",
  siteUrl: "http://suzuki.com.do/",
  url: "http://back.suzuki.com.do/graphql",
  path: "https://back.suzuki.com.do/",
  language: "es",
  logo: "src/assets/img/favicon-32x32.png", // Used for SEO and manifest
  description: "",
  author: "Joel Lembert | Ysidro Almonte - Omd Dominicana",
  uff: "https://back.suzuki.com.do/", // url asiganda para conextar con el plugin de los formularios.
  url_flexible_form: "https://back.suzuki.com.do/", 
// Manifest and Progress color
  themeColor: "#023690",
  backgroundColor: "#ffffff",
}
