import { dashConstants } from '../_constants'

function chats(state = {}, action) {
  switch (action.type) {
    case dashConstants.CHAT_SUCCESS:
      return action.data
    case dashConstants.CHAT_FAILURE:
      return {}
    default:
      return state
  }
}

function cotizaciones(state = {}, action) {
  switch (action.type) {
    case dashConstants.COTIZACION_SUCCESS:
      return action.data
    case dashConstants.COTIZACION_FAILURE:
      return {}
    default:
      return state
  }
}

export {
  chats,
  cotizaciones
}