const { path } = require("../../../config/app");

export const dashService = {
  chats,
  cotizaciones
};

function chats(email) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" }
  };

  return fetch(
    `${path}/wp-json/sdm/v1/wp_wplc_chat_sessions/${encodeURI(email)}`,
    requestOptions
  )
    .then(handleResponse)
    .then(cotizaciones => {
      if (cotizaciones) {
        return cotizaciones;
      }
    });
}

function cotizaciones(email) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" }
  };

  return fetch(
    `${path}/wp-json/sdm/v1/cotizaciones/${encodeURI(email)}`,
    requestOptions
  )
    .then(handleResponse)
    .then(cotizaciones => {
      if (cotizaciones) {
        return cotizaciones;
      }
    });
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    console.log({ data });
    if (!response.ok) {
      console.log(response);
      if (response.status === 401) {
      }
      const error = "Credenciales incorrectas.";
      return Promise.reject(error);
    }
    if (response.status === 204) {
      const error = "Credenciales incorrectas.";
      return Promise.reject(error);
    }
    return data;
  });
}
