import React from "react";
// import { Link } from "gatsby";
import ChevroletList from "../ChevroletList/";
import { path } from "../../../config/app.js";

const MenuItem = (items) => {

  const item = items.items.node;

  const url = item.url
    .substring(path)
    .split("/")
    .splice(-2)[0];
  
  if (item.menuItemId === 376) {
    return (
      <li
        className="uk-visible@m uk-margin-large uk-margin-remove-vertical"
        key={item.menuItemId}
      >
        <a href={url}>{item.label}</a>
        <div
          id="drop-menu-content"
          data-uk-dropdown="pos: bottom-center; boundary: #top-menu; offset: 0"
        >
            <h3>Elige tu Modelo</h3>
            <ChevroletList />
        
        </div>
      </li>
    );
  }

  if (item.menuItemId === 3402) {
    return (
      <li
        className="uk-visible@m uk-margin-large uk-margin-remove-vertical"
        key={item.menuItemId}
      >
        <a href={url}>{item.label}<span className="navbar-icon"> <svg xmlns="http://www.w3.org/2000/svg" width="28.689" height="28.695" viewBox="0 0 28.689 28.695"> <g id="Group_4" data-name="Group 4" transform="translate(-2790.276 -402.549)"> <path id="Path_16" data-name="Path 16" d="M2818.965,416.9a14.345,14.345,0,1,1-14.321-14.355A14.371,14.371,0,0,1,2818.965,416.9Zm-14.3-10.255a10.248,10.248,0,1,0,10.2,10.285A10.263,10.263,0,0,0,2804.666,406.649Z" transform="translate(0 0)" /> <path id="Path_17" data-name="Path 17" d="M2946.053,685.235l2.625-3.188a3.987,3.987,0,0,0,3.694-.134l2.534,3.239A8,8,0,0,1,2946.053,685.235Z" transform="translate(-145.784 -261.442)" /> <path id="Path_18" data-name="Path 18" d="M2891.039,596.664l-2.609,3.172a7.9,7.9,0,0,1-1.927-7.779l2.143.9c.491.207.984.409,1.47.626.078.035.178.135.181.209A4.229,4.229,0,0,0,2891.039,596.664Z" transform="translate(-89.737 -177.35)" /> <path id="Path_19" data-name="Path 19" d="M3072.361,591.77a8.26,8.26,0,0,1-1.818,7.708l-2.612-3.336a3.944,3.944,0,0,0,.593-2.586.261.261,0,0,1,.118-.214C3069.863,592.819,3071.089,592.306,3072.361,591.77Z" transform="translate(-259.843 -177.082)" /> <path id="Path_20" data-name="Path 20" d="M3029.93,499.474a8.275,8.275,0,0,1,6.033,4.03l-.966.408c-.883.373-1.768.741-2.647,1.123a.29.29,0,0,1-.4-.093,4.038,4.038,0,0,0-1.833-1.235.355.355,0,0,1-.212-.241c-.011-1.3-.006-2.6,0-3.905A.375.375,0,0,1,3029.93,499.474Z" transform="translate(-224.246 -90.707)" /> <path id="Path_21" data-name="Path 21" d="M2909.748,499.406v1.27c0,.875,0,1.75.005,2.626,0,.194-.034.3-.246.366a4.009,4.009,0,0,0-1.84,1.225.327.327,0,0,1-.278.1c-1.212-.5-2.417-1.01-3.624-1.52-.016-.007-.025-.032-.06-.079A8.255,8.255,0,0,1,2909.748,499.406Z" transform="translate(-106.153 -90.643)" /> <path id="Path_22" data-name="Path 22" d="M2984.014,594.266a2.049,2.049,0,1,1-2.062,2.043A2.049,2.049,0,0,1,2984.014,594.266Z" transform="translate(-179.38 -179.417)" /> </g> </svg></span></a>

        {/* //TODO Tomar esta navegación dinamica desde wp con graphql */}
        <div className="uk-navbar-dropdown dropdown--vermas">
                    <ul id="drop-menu-content" className="uk-nav uk-navbar-dropdown-nav" >
                      <li><a href="/servicios">Servicios</a></li>
                      <li><a href="/contacto">Contacto</a></li>
                      <li><a href="/acerca-de-suzuki">Acerca de Suzuki</a></li>
                      <li><a href="/puntos-de-ventas">Puntos de Venta</a></li>
                      <li><a href="/cotizar-piezas">Cotizar Piezas</a></li>
                    </ul>
                  
                </div>
      </li>
    );
  }

  if (item.menuItemId === 4138) {
    return (
      <li
        className="uk-visible@m uk-margin-large uk-margin-remove-vertical uk-padding-small uk-padding-remove-vertical"
        key={item.menuItemId}
      >
        <a href={url} className="btn btn--secondary-virtual btn--navbar">
         {item.label}
        </a>
      </li>
    );
  }


  if (item.menuItemId === 3370) {
    return (
      <li
        className="uk-visible@m uk-margin-large uk-margin-remove-vertical"
        key={item.menuItemId}
      >
        <a href={url} className="btn btn--primary btn--navbar">
        <span className="navbar-icon uk-padding-remove-left"><svg xmlns="http://www.w3.org/2000/svg" width="27.053" height="27.056" viewBox="0 0 27.053 27.056"> <g id="Group_5" data-name="Group 5" transform="translate(-3360.452 -370.202)"> <path id="Path_23" data-name="Path 23" d="M3373.132,370.2h1.691c.076.015.153.033.23.046.737.119,1.487.184,2.209.362a13.523,13.523,0,1,1-16.609,15.422c-.086-.483-.135-.973-.2-1.459v-1.691c.086-.6.151-1.2.262-1.795a13.516,13.516,0,0,1,10.906-10.677C3372.122,370.328,3372.628,370.271,3373.132,370.2Zm11.191,13.532a10.345,10.345,0,1,0-10.34,10.338A10.374,10.374,0,0,0,3384.323,383.734Z" transform="translate(0 0)"/> <path id="Path_24" data-name="Path 24" d="M3468.084,526.082c-.115.728-.234,1.455-.343,2.183-.028.188-.119.255-.307.277a6.882,6.882,0,0,0-3.153,1.107,5.9,5.9,0,0,0-2.427,3.791c-.064.292-.091.591-.156.883a.316.316,0,0,1-.171.209,8.638,8.638,0,0,1-4.537.012c-.148-.038-.167-.121-.182-.242a7.729,7.729,0,0,0-.585-2.237,5.8,5.8,0,0,0-4.331-3.388c-.292-.063-.592-.091-.882-.156a.312.312,0,0,1-.2-.178,8.578,8.578,0,0,1,.428-5.751.506.506,0,0,1,.218-.21,14.776,14.776,0,0,1,4.847-1.632,18.73,18.73,0,0,1,5.549-.056,14.851,14.851,0,0,1,5.213,1.687.625.625,0,0,1,.25.28,8.692,8.692,0,0,1,.706,3.412Zm-11.206-.016a2.38,2.38,0,1,0,2.4-2.365A2.38,2.38,0,0,0,3456.877,526.067Z" transform="translate(-85.28 -142.355)"/> <path id="Path_25" data-name="Path 25" d="M3601.749,610.679a.8.8,0,1,1-.817.76A.788.788,0,0,1,3601.749,610.679Z" transform="translate(-227.749 -227.747)"/> </g> </svg></span> {item.label}
        </a>
      </li>
    );
  }
  

  return (
    <li
      key={item.menuItemId}
      className="uk-margin-large uk-margin-remove-vertical"
    >
      <a href={"/" + url}> {item.label} </a>
    </li>
  );
};
export default MenuItem;
