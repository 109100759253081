/* eslint-disable */
import React, { Component } from 'react';
import { connect }          from 'react-redux';
import { searchActions }    from '../redux/_actions';
import ModelFilter from '../components/ModelFilter';
import { ClipLoader } from 'react-spinners';
import { delay } from '../utils/utils';

class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search: '',
      result: '',
      loading: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleKeyUp = this.handleKeyUp.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.resultSearch && this.state.loading) {
      this.setState({loading: false});
    }
  }

  handleChange(e) {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleKeyUp = delay( e => {
    //e.preventDefault();
    const { search } = this.state;
    const { dispatch } = this.props;


    if (search) {

      this.setState({loading: true});
      dispatch(searchActions.search(search));
    }
  }, 300)

  render() {
     const { resultSearch, alert } = this.props
     const { search } = this.state


    return (
        <div className="uk-width-1-1 uk-padding-small uk-padding-remove-horizontal">

            <div className="uk-flex uk-flex-middle">
            <form autoComplete="false"
                  className="uk-flex-1 uk-margin-small-right uk-search uk-search-large sdm-search-large">

                <span data-uk-search-icon className="uk-search-icon-flip"></span>

                <input id="input"
                  className="uk-search-input uk-padding-small"
                  type="search" name="search"
                  placeholder="Buscar..."
                  value={search || ''}
                  onKeyUp={this.handleKeyUp}
                  onChange={this.handleChange}
                  onKeyPress={ e => {
                    if (e.key === 'Enter') e.preventDefault();
                  } } autoFocus />

          </form>

          <ClipLoader
            sizeUnit={"px"}
            size={20}
            color={'#9E9E9E'}
            loading={this.state.loading} />
          </div>

          <div className="uk-width-1-1">
                {(resultSearch && resultSearch.modelos.length !== 0) ?
                  <ModelFilter modelos={resultSearch.modelos} mode="search"/> : <span>{alert && alert.type === 'alert-danger' ? alert.message : null}</span>
                }

                {(resultSearch && resultSearch.paginas.length !== 0) ?
                  resultSearch.paginas.map( p =>
                    <dl key={p.id} className="uk-description-list">
                      <dt><a onClick={this.redirectPage.bind(this, p)}>{p.title.rendered}</a></dt>

                    </dl>
                  )
                : <span>{alert && alert.type === 'alert-danger' ? alert.message : null}</span>
                }
          </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
    const searchResult = state.search;
    const { alert } = state;

    let result = typeof searchResult === "object" ? searchResult : JSON.parse(searchResult)
    let resultSearch = { paginas: [], dealers: [], modelos: [] }

    if(result.length > 0 ){
        result.forEach( function(i, index) {
          resultSearch.modelos.push(i);
        });
    }
  return { alert, resultSearch };
}

export default connect(mapStateToProps)(Search)