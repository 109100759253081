import React from "react"
import { navigateTo } from "gatsby-link"
//import Img from "gatsby-image"

class FilterContent extends React.Component {
  render() {
    const { node } = this.props

     return (

                 <a  href={`#{node.slug}`}

                            onClick={() => {
                              navigateTo(`/${node.slug}`)
                            }}
                          >
                  <h3 className="uk-card-title">

                    {node.title.rendered}
                  </h3>
                   <p dangerouslySetInnerHTML={{ __html: node.excerpt.rendered }} />
                    <hr  />
                   </a>

        )
  }
}

export default FilterContent






/*

<div  className="uk-card uk-card-default">
                <div className="uk-card-media-top uk-padding-small">
                    {node.featured_media && node.featured_media.source_url ? (
                    <img
                      src={node.featured_media.source_url}
                      alt={node.title.rendered}
                    />
                  ) : node.image_url && node.image_url.localFile ? (
                    <Img
                      fixed={node.image_url.localFile.childImageSharp.fixed}
                      alt={node.title.rendered}
                    />
                  ) : node._embedded && node._embedded["wp:featuredmedia"] ? (
                    <img
                      src={node._embedded["wp:featuredmedia"][0].source_url}
                      alt={node.title.rendered}
                    />
                  ) : (
                    <img src={node.image_url} alt={node.post_title} />
                  )}
                </div>
                <div className="uk-card-body">
                  <h3 className="uk-card-title">

                    {node.title.rendered}
                  </h3>
                    <div className="uk-flex uk-flex-center">
                          <button
                            type="button"

                            className="uk-button uk-button-default uk-button-small uk-boder-yelow "
                            onClick={() => {
                              navigateTo(`/${node.slug}`)
                            }}
                          >
                            Más información
                          </button>
                          <br />
                          <button
                            type="button"

                            className="uk-button uk-button-default uk-button-small uk-boder-yelow "

                            onClick={() => {
                              navigateTo(`/test-drive/`)
                            }}
                          >
                            Prueba de manejo
                          </button>
                          <br />
                          <button
                            type="button"

                            className="uk-button uk-button-default uk-button-small uk-boder-yelow "

                            onClick={() => {
                              navigateTo(`/solicitud-cotizacion`)
                            }}
                          >
                            Cotización
                          </button>
                    </div>
                </div>
            </div>


 */