
import { formService } from '../_services'
import { formConstants } from '../_constants'
import { alertActions } from '.'

export const formActions = {
    chassis,
    testDrive,
    Cotizaciones,
    cotizacion
}

function chassis(data) {
    return dispatch => {
        dispatch(request(data));

        formService.chassis(data)
        .then(
            data => {
                dispatch(success(data))
                dispatch(alertActions.success('Busqueda exitosa.'))
            },
            error => {
                dispatch(failure(error.toString()))
                dispatch(alertActions.error(error.toString()))
            }
        )
    }

    function request(data) { return { type: formConstants.CHASSIS_REQUEST, data } }
    function success(data) { return { type: formConstants.CHASSIS_SUCCESS, data } }
    function failure(error) { return { type: formConstants.CHASSIS_FAILURE, error } }
}



function testDrive(data) {
    return dispatch => {

        dispatch(request(data));

        formService.testDrive(data)
        .then(
            data => {
                dispatch(success(data))
                dispatch(alertActions.success('Enviado con exito.'))
            },
            error => {
                dispatch(failure(error.toString()))
                dispatch(alertActions.error(error.toString()))
            }
        )
    }

    function request(data) { return { type: formConstants.TESTDRIVE_REQUEST, data } }
    function success(data) { return { type: formConstants.TESTDRIVE_SUCCESS, data } }
    function failure(error) { return { type: formConstants.TESTDRIVE_FAILURE, error } }
}

function cotizacion(data) {
    return dispatch => {
        dispatch(request(data));

        formService.cotizacion(data)
        .then(
            data => {
                dispatch(success(data))
                dispatch(alertActions.success(data))
            },
            error => {
                dispatch(failure(error.toString()))
                dispatch(alertActions.error(error.toString()))
            }
        )
    }

    function request(data) { return { type: formConstants.COTIZACION_REQUEST, data } }
    function success(data) { return { type: formConstants.COTIZACION_SUCCESS, data } }
    function failure(error) { return { type: formConstants.COTIZACION_FAILURE, error } }
}

function Cotizaciones(email) {
    return dispatch => {
        dispatch(request({email}));

        formService.Cotizaciones(email)
        .then(
            cotizaciones => {
                dispatch(success(cotizaciones))
                dispatch(alertActions.success('Success'))
            },
            error => {
                dispatch(failure(error.toString()))
                dispatch(alertActions.error(error.toString()))
            }
        )
    }

    function request(data) { return { type: formConstants.COTIZACION_REQUEST, data } }
    function success(data) { return { type: formConstants.COTIZACION_SUCCESS, data } }
    function failure(error) { return { type: formConstants.COTIZACION_FAILURE, error } }
}