const { path } = require('../../../config/app')

export const formService = {
  chassis,
  testDrive,
  Cotizaciones,
  cotizacion
};

function chassis(data) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'text/xml' }
  };

  return fetch(`${path}/wp-json/sdm/v1/campaign?vehicle_identification_number=${data}`, requestOptions)
  .then(handleResponse)
  .then(chassis => {
    if (chassis) { return chassis }
  })
}

function testDrive(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetch(`${path}/wp-json/sdm/v1/testdrive`, requestOptions)
  .then(handleResponse)
  .then(testdrive => {
    if (testdrive) {
      return testdrive
    }
  })
}

function cotizacion(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetch(`${path}/wp-json/sdm/v1/cotizaciones`, requestOptions)
  .then(handleResponse)
  .then(cotizacion => {
    if (cotizacion) {
      return cotizacion
    }
  })
}

function Cotizaciones(email) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  };

  return fetch(`${path}/wp-json/sdm/v1/cotizaciones/${encodeURI(email)}`, requestOptions)
  .then(handleResponse)
  .then(cotizaciones => {
    if (cotizaciones) { return cotizaciones }
  })
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text)
    console.log({data});
    if (!response.ok) {
      console.log(response);
      if (response.status === 401) {
      }
      const error = 'Credenciales incorrectas.';
      return Promise.reject(error)
    }
    if(response.status === 204) {
      const error = 'Credenciales incorrectas.'
      return Promise.reject(error)
    }
    return data
  });
}