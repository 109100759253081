const { path } = require('../../../config/app')
var ls = require('local-storage')

export const userService = {
  login,
  logout,
  register
};

function login(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({username,password})
  };
    
  return fetch(`${path}/wp-json/jwt-auth/v1/token`, requestOptions)
  .then(handleResponse)
  .then(user => {
    if (user) {
      ls.set('user', JSON.stringify(user))
      return user
    }
  })
}

function logout() {
  ls.remove('user')
}

function register(user) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3Q6ODA4OCIsImlhdCI6MTUzNDUzMjI1NiwibmJmIjoxNTM0NTMyMjU2LCJleHAiOjE1MzUxMzcwNTYsImRhdGEiOnsidXNlciI6eyJpZCI6IjEifX19.PEitBWMpt0DXwRpAFE1V9dB8eKyqreAwvTx-xN_IjO4',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(user)
  };
  return fetch(`${path}/wp-json/wp/v2/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      console.log(response.ok);
      if (response.status === 401) {
        logout()
        // location.reload(true)
      }
      const error = 'Credenciales incorrectas.';
      return Promise.reject(error)
    }
    if(response.status === 204) {
      const error = 'Credenciales incorrectas.'
      return Promise.reject(error)
    }
    return data
  });
}