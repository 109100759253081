const { path } = require("../../../config/app");

export const searchService = {
  search
};

function search(s) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" }
  };

  return fetch(
    `${path}/wp-json/swp_api/search?s=${encodeURI(s)}&_embed`,
    requestOptions
  )
    .then(handleResponse)
    .then(s => {
      if (s) {
        return s;
      }
    });
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        // location.reload(true)
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    if (data.length < 1) {
      const error = "No se encontraron resultados.";
      return Promise.reject(error);
    }
    return data;
  });
}
