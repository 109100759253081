
import { searchService } from '../_services'
import { searchConstants } from '../_constants'
import { alertActions } from './'
//import { push } from "gatsby-link"

export const searchActions = { search }

function search(s) {

    return dispatch => {
        dispatch(request({ s }))

        searchService.search(s)
        .then(
            search => {
                dispatch(success(search))
                dispatch(alertActions.success('Busqueda exitosa.'))
            },
            error => {
                dispatch(failure(error.toString()))
                dispatch(alertActions.error(error.toString()))
            }
        )
    }

    function request(search) { return { type: searchConstants.SEARCH_REQUEST, search } }
    function success(search) { return { type: searchConstants.SEARCH_SUCCESS, search } }
    function failure(error) { return { type: searchConstants.SEARCH_FAILURE, error } }
}