module.exports = {
  telFormat: function(number) {
    return number.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, "($1) $2 $3");
  },
  delay: function(callback, ms) {
    var timer = 0;
    return () => {
      var context = this, args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        callback.apply(context, args);
      }, ms || 0);
    };
  },
  disabled: function( elements ) {
    if( elements.length === 1 ) {
      let element = elements;
      if( !element.classList.contains('disabled') ) {
        element.classList.add('disabled');
      } else {
        element.classList.remove('disabled');
      }
    } else {
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        if( !element.classList.contains('disabled') ) {
          // window.animatedBtn.pause();
          if(window.animatedBtn.children) {
            window.animatedBtn.children.map( btn =>
              btn.pause()
            )
          } else {
            window.animatedBtn.pause();
          }
          element.classList.add('disabled');
        } else {
          // window.animatedBtn.play();
          if(window.animatedBtn.children) {
            window.animatedBtn.children.map( btn =>
              btn.play()
            )
          }
          else {
            window.animatedBtn.play();
          }
          element.classList.remove('disabled');
        }
      }
    }
  },
  animated: function( animation, element ) {
    let anime = require('animejs');
    if( anime() ) {
      setTimeout(() => {
        if(animation === 'color') {
          anime({
            targets: element,
            fill: [
              {value: '#fdf5cf'}, // Or #FFFFFF
              {value: 'rgb(255, 215, 0)'},
              {value: 'hsl(60, 100%, 40%)'}
            ],
            stroke: [
              {value: '#fdf5cf'}, // Or #FFFFFF
              {value: 'rgb(255, 215, 0)'},
              {value: 'hsl(60, 100%, 40%)'}
            ],
            easing: 'linear',
            direction: 'alternate',
            duration: 2000,
            loop: true,
            autoplay: true
          });
        } else {
          let elements = Array.prototype.slice.call(document.querySelectorAll(element)),
          animatedBtn;

          if(elements.length > 1) {
            animatedBtn = anime.timeline({
              targets: element,
              delay: function(el, i) { return i * 1000 },
              easing: 'linear',
              direction: 'right',
              duration: 10000,
              loop: true
            });

            elements.map( (elem, index) =>
            animatedBtn
            .add({
              strokeDashoffset: [anime.setDashoffset, 0]
            })
            )
          } else {
            animatedBtn = anime({
              targets: element,
              strokeDashoffset: [anime.setDashoffset, 0],
              easing: 'linear',
              direction: 'right',
              duration: 10000,
              loop: true,
              autoplay: true
            });
          }

          window.animatedBtn = animatedBtn;
        }
      }, 1000);
    }
  },
  toSwitcher: function(step = '#ofertas', switcher = '.switcher') {
  //  const UIkit = require('uikit');
    //console.log({step, switcher: document.querySelector(switcher)});
    //let childrens = Array.prototype.slice.call(document.querySelector(switcher).childNodes);

    /*childrens.map( (child, index) =>
      child.id === step.substr(1) ?
      UIkit.switcher(switcher).show(index) : null
    )*/
  },
  loadMoreItems: function(elem, more, less, numberShow = 10) {
    const $ = require('jquery');

    let list = $(elem),
    numToShow = numberShow,
    button = $(more),
    buttonLess = $(less),
    numInList = list.length;

    if (numInList > numToShow) {
      buttonLess.show();
      if( !button.attr('clicked') ) {
        button.show();
        buttonLess.hide();
        list.hide();

        list.slice(0, numToShow).show();
      }

      button.click(function(){
        list.show();
        button.attr('clicked', true)
        button.hide();
        buttonLess.show();
      });

      buttonLess.click(function(){
        list.hide();
        list.slice(0, numToShow).show();
        buttonLess.hide();
        button.attr('clicked', null);
        button.show();
      });
    } else {
      button.hide();
      buttonLess.hide();
    }
  },
  sumarMeses: function(fecha, meses = 0) {
    var options = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      hour12: false
    };
    var newDate = new Date(fecha);
    newDate.setMonth(newDate.getMonth() + meses);
    newDate.setDate (newDate.getDate() - meses);

    return newDate.toLocaleDateString('es-Es', options)
  },
  unserialize: function (data) {
    var that = this,
      utf8Overhead = function (chr) {
        var code = chr.charCodeAt(0);
        if (code < 0x0080) {
          return 0;
        }
        if (code < 0x0800) {
          return 1;
        }
        return 2;
      },
      error = function (type, msg, filename, line) {
        throw new that.window[type](msg, filename, line);
      },
      read_until = function (data, offset, stopchr) {
        var i = 2, buf = [], chr = data.slice(offset, offset + 1);

        while (chr !== stopchr) {
          if ((i + offset) > data.length) {
            error('Error', 'Invalid');
          }
          buf.push(chr);
          chr = data.slice(offset + (i - 1), offset + i);
          i += 1;
        }
        return [buf.length, buf.join('')];
      },
      read_chrs = function (data, offset, length) {
        var i, chr, buf;

        buf = [];
        for (i = 0; i < length; i++) {
          chr = data.slice(offset + (i - 1), offset + i);
          buf.push(chr);
          length -= utf8Overhead(chr);
        }
        return [buf.length, buf.join('')];
      },
      _unserialize = function (data, offset) {
        var dtype, dataoffset, keyandchrs, keys, contig,
          length, array, readdata, readData, ccount,
          stringlength, i, key, kprops, kchrs, vprops,
          vchrs, value, chrs = 0,
          typeconvert = function (x) {
            return x;
          };

        if (!offset) {
          offset = 0;
        }
        dtype = (data.slice(offset, offset + 1)).toLowerCase();

        dataoffset = offset + 2;

        switch (dtype) {
          case 'i':
            typeconvert = function (x) {
              return parseInt(x, 10);
            };
            readData = read_until(data, dataoffset, ';');
            chrs = readData[0];
            readdata = readData[1];
            dataoffset += chrs + 1;
            break;
          case 'b':
            typeconvert = function (x) {
              return parseInt(x, 10) !== 0;
            };
            readData = read_until(data, dataoffset, ';');
            chrs = readData[0];
            readdata = readData[1];
            dataoffset += chrs + 1;
            break;
          case 'd':
            typeconvert = function (x) {
              return parseFloat(x);
            };
            readData = read_until(data, dataoffset, ';');
            chrs = readData[0];
            readdata = readData[1];
            dataoffset += chrs + 1;
            break;
          case 'n':
            readdata = null;
            break;
          case 's':
            ccount = read_until(data, dataoffset, ':');
            chrs = ccount[0];
            stringlength = ccount[1];
            dataoffset += chrs + 2;

            readData = read_chrs(data, dataoffset + 1, parseInt(stringlength, 10));
            chrs = readData[0];
            readdata = readData[1];
            dataoffset += chrs + 2;
            if (chrs !== parseInt(stringlength, 10) && chrs !== readdata.length) {
              error('SyntaxError', 'String length mismatch');
            }
            break;
          case 'a':
            readdata = {};

            keyandchrs = read_until(data, dataoffset, ':');
            chrs = keyandchrs[0];
            keys = keyandchrs[1];
            dataoffset += chrs + 2;

            length = parseInt(keys, 10);
            contig = true;

            for (i = 0; i < length; i++) {
              kprops = _unserialize(data, dataoffset);
              kchrs = kprops[1];
              key = kprops[2];
              dataoffset += kchrs;

              vprops = _unserialize(data, dataoffset);
              vchrs = vprops[1];
              value = vprops[2];
              dataoffset += vchrs;

              if (key !== i)
                contig = false;

              readdata[key] = value;
            }

            if (contig) {
              array = new Array(length);
              for (i = 0; i < length; i++)
                array[i] = readdata[i];
              readdata = array;
            }

            dataoffset += 1;
            break;
          default:
            error('SyntaxError', 'Unknown / Unhandled data type(s): ' + dtype);
            break;
        }
        return [dtype, dataoffset - offset, typeconvert(readdata)];
      }
    ;

    return _unserialize((data + ''), 0)[2];
  }

}