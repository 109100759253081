import { searchConstants } from '../_constants'

export function search(state = {}, action) {
  switch (action.type) {
    case searchConstants.SEARCH_SUCCESS:
      return action.search
    case searchConstants.SEARCH_FAILURE:
      return {}
    default:
      return state
  }
}