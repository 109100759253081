import React, { Component } from "react"
import Switchers from "./Switchers"
import { graphql, StaticQuery } from "gatsby"


class ChevListMobile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categories: this.props.categories.edges,
        }
    }


  render() {

    return (
        <>

        <ul className="uk-nav-sub ">
        {this.state.categories.map(
                    trem =>
                      trem.node.id !== "Y2F0ZWdvcnk6MQ==" && (
                        <li
                          key={trem.node.id}
                         className="uk-margin"
                        >
                          {/* <a className="uk-accordion-title uk-padding-small" href={trem.node.slug}>
                            <span> {trem.node.name} </span>
                          </a> */}
                          <div className="uk-flex uk-flex-middle">

                            <Switchers className="uk-padding-small" items={trem.node.chevrolets.edges} />
                          </div>
                        </li>
                      )
                  )}

{/*
                      <div className="uk-flex uk-flex-middle">
                        <div>
                          <img src="https://via.placeholder.com/190x120" />
                        </div>
                        <div className="uk-padding-small uk-padding-remove-vertical">
                          <h4 className="uk-margin-remove">Swift</h4>
                          <p className="uk-text-light uk-margin-remove">
                            lorem lore imspum dolor simir
                          </p>
                        </div>
                      </div>*/}


                  </ul>

        </>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        chevrolet {
          categories(where: { hideEmpty: true, orderby: TERM_ID }) {
            edges {
              node {
                id
                name
                count
                chevrolets(first: 100) {
                  edges {
                    node {
                      id
                      chevroletId
                      slug
                      title
                      featuredImage {
                        id
                        sourceUrl
                      }
                      mostrarPalabraDesde
                      versiones {
                        edges {
                          node {
                            precio_version
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ chevrolet: { categories } }) => (
      <ChevListMobile categories={categories} />
    )}
  />
)

