import React, { Component } from "react";
import { graphql, StaticQuery } from "gatsby";
import { IconContext } from "react-icons";
import { RiPhoneFill } from "react-icons/ri";
// import { MdPermPhoneMsg } from "react-icons/md";
import { FaTwitter, FaFacebookF, FaInstagram, FaYoutube, FaWhatsapp } from "react-icons/fa";

import Suscripcion from "../formulario/formulario-suscripcion"
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//import { envelope, phone } from "@fortawesome/fontawesome-common-types"
//import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"
// import FA from "react-fontawesome"
// import fnr from "../../assets/img/fnr.png";
// import lsdm from "../../assets/img/logo-sdm.png"
import logo from "../../assets/img/logo-footer.svg";

class Footer extends Component {
  render() {
    const actualYear = new Date();

    return (
      <footer className="uk-padding uk-margin-large-top">
        <div className="uk-container">
          <div
            className="uk-margin-top uk-margin-bottom"
            data-uk-grid>
            <div className="uk-width-1-1 uk-width-1-2@m">
              <h3>Atención al Cliente</h3>
              <ul className="uk-list uk-flex-center uk-flex-left@m" data-uk-grid>
                <li className="uk-flex uk-flex-middle">
                  <IconProvider>
                    <RiPhoneFill />
                  </IconProvider>

                  <a
                    href="tel:8095403800"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    (809)540-3800
                  </a>
                </li>
                <li className="uk-flex uk-flex-middle">
                  <IconProvider>
                    <FaWhatsapp />
                  </IconProvider>
                  <a
                    href="https://api.whatsapp.com/send?phone=18295215151"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    (829)521-5151
                  </a>
                </li>
              </ul>
            </div>
            <div className="uk-width-1-1 uk-width-1-4@m">
              <h3>Redes Sociales</h3>

              <ul className="uk-list uk-flex uk-flex-center uk-flex-left@m">
                <li>
                  <a href="https://www.instagram.com/suzukird/" rel="noopener noreferrer" target="_blank">
                    <IconProvider>
                      <FaInstagram />
                    </IconProvider>
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/suzukird/" rel="noopener noreferrer" target="_blank">
                    <IconProvider>
                      <FaFacebookF />
                    </IconProvider>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/suzukird/" rel="noopener noreferrer" target="_blank">
                    <IconProvider>
                      <FaTwitter />
                    </IconProvider>
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/channel/UCqpY50mUl09eGhnWj_ID5Kg" rel="noopener noreferrer" target="_blank">
                    <IconProvider>
                      <FaYoutube />
                    </IconProvider>
                  </a>
                </li>
              </ul>
            </div>
            <div className="uk-width-1-1 uk-width-1-4@m">
            <h3>Boletín de Noticias</h3>
              <Suscripcion />
            </div>

          </div>
        </div>

        <hr />

        <div className="uk-container">
          <div
            className="uk-grid uk-margin-top uk-margin-bottom uk-flex-center@s"
            data-uk-grid="true"
          >
            <div className="uk-width-expand@m">
              <img src="https://back.suzuki.com.do/wp-content/uploads/2022/07/SUZUKI_horizontal_657x159px.png" alt="Suzuki Motors República Dominicana" class="uk-margin-right" width="180px"/>
              
              <div>
                <small>
                  ©{actualYear.getFullYear()} Suzuki motor república dominicana.
                  Todos los derechos reservados.
                </small>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

// {Context Provider Para customizar Icons}
class IconProvider extends Component {
  render() {
    return (
      <>
        <IconContext.Provider
          value={{ size: "1.2em", className: "global-class-name" }}
        >
          <span className="round-icons">{this.props.children}</span>
        </IconContext.Provider>
      </>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        chevrolet {
          menu(id: "TWVudToxOQ==") {
            id
            menuItems {
              edges {
                node {
                  id
                  menuItemId
                  url
                  label
                }
              }
            }
          }
        }
      }
    `}
    render={({ chevrolet: { menu } }) => <Footer menus={menu} />}
  />
);
