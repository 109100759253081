/* eslint-disable */
import React                from 'react'
import Link, { navigateTo } from 'gatsby-link'
import Img                  from 'gatsby-image'
//import Uikit                from 'uikit'
import FilterContent from './filterContent'

class ModelFilter extends React.Component {

  componentDidMount() {


    if(this.props.mode !== 'search' ) {
      let parent = this
      setTimeout(() => {
        let elem = $('body .sdm-modelos-cat li.uk-active a');
        let hover = $('body .sdm-btn-hover-d');
        this.addCss(elem, `color: ${this.props.color} !important`, false)
        this.addCss(hover, `background-color: ${this.props.color} !important`, true)
      }, 2000);
      $('.uk-switcher').on('show', function () {
        let elems = $('body .sdm-modelos-cat li a')
        let elem = $('body .sdm-modelos-cat li.uk-active a')
        parent.addCss(elems, "color: #707070 !important", false)
        parent.addCss(elem, `color: ${parent.props.color} !important`, false)
      });
    }
  }

  addCss(elem, style, isHover) {
    if(isHover) {
      elem.mouseover(function(){
        $(this).css('cssText', style)
        $(this).css('border', "none")
      });
      elem.mouseout(function(){
        $(this).css("background-color","")
        $(this).css('border', "1px solid")
      });
    } else {
      elem.css('cssText', style)
    }
  }

  render() {
    const { modelos, mode } = this.props;

    return (<div className="uk-position-relative">
                { mode === 'search' ? <hr className="sdm-switcher-hr-search"/> : null}
                <ul className="uk-list uk-margin">
                         { modelos.map(( modelo, index) => {
                              let node = modelo.node || modelo;
                              return <li><FilterContent key={index} mode={mode} node={node} /></li>
                            })}


                      </ul>
            </div>)
  }
}

export default ModelFilter


/*

<div className={`uk-grid-match uk-grid-small uk-child-width-1-1@s uk-child-width-1-3@m ${mode === 'search' ? 'uk-flex-left': 'uk-flex-center'}  uk-text-center`} data-uk-grid>
                            { modelos.map(( modelo, index) => {
                              let node = modelo.node || modelo;
                              return <FilterContent key={index} mode={mode} node={node} />

                            })}
                            </div>
 */
