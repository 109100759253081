import React from "react"
// import { StaticQuery, graphql, Link } from "gatsby"
// import shadowncard from "../../assets/img/default.png"
// import InputMask from "react-input-mask"
const config = require("../../../config/app");


class Suscripcion extends React.Component {
    constructor(props) {
        super(props)
  
        this.state = {
            btnText:"ENVIAR",
            requesId: false,
            alert: true,
            alertType: "uk-hidden",
            error: false,
            feedBackInput: {
                email: "",
            },
            urlOrginForm: config.url_flexible_form,

        }
        this.SendEntries = this.SendEntries.bind(this)
    }
    SendEntries(v) {
      v.preventDefault()
      const datetime = Date.now();

      const raw = JSON.stringify({
        "flexformdata":
        {
          "FormCode": [[{ "value": "NjE1ZGJmNGRjNmUzZQ==" },{ "datetime": datetime }, { "action": 1 }]], 
          "correo": [[{ "value": this.state.feedBackInput.email },{ "datetime": datetime }, { "action": 1 }]]
        }
      });

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Access-Control-Allow-Origin", "*");
      const requestOptions = {
        method: 'POST',
        'mode': 'cors',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

        if (typeof window !== "undefined") {
            this.setState({btnText:"ENVIANDO..."})
          const suscripcionForm = Array.from(document.forms["suscripcionForm"]);
          let data = { flexformdata: {} }
          suscripcionForm.forEach(n =>
            n.type === "email" ? ( n.value ? data["flexformdata"][n.name] =  n.value : data["flexformdata"][null])
              : ""
          )
            //document.querySelector('.btn--suscribe').setAttribute("disabled","disabled");
          if(JSON.stringify(data["flexformdata"]).length > 2){
             
            // if (!err) {
                fetch(
                  this.state.urlOrginForm +
                    `wp-json/OmdFlex_forms/v1/save_form/615dbf4dc6e3e`,
                 requestOptions
                )
                .then(res => {
                    // document.querySelector('.btn--suscribe').removeAttribute('disabled');
                    this.setState({
                      btnText:"ENVIAR",
                      alert: true,
                      alertType:"uk-alert-success",
                      alertMessage:"Tu solicitud ha sido enviada!",
                      error: "uk-alert-success",
                      feedBackInput: {
                        email: "",
                      },
                    })

                    setInterval(() => {
                      this.setState({alertType: 'uk-hidden'})
                      }, 2000);
                   
                  })
                  .catch(fail => {
                    //document.querySelector('.btn--suscribe').removeAttribute('disabled');
                    this.setState({
                      btnText:"ENVIAR",
                        alert: true,
                        alertType:"uk-alert-danger",
                        alertMessage:"upss.. No se ha completado tu solicitud.",
                        error: "uk-alert-danger",
                        feedBackInput: {
                          email: "",
                        },
                      })
                      setInterval(() => {
                      this.setState({alertType: 'uk-hidden'})
                      }, 2000);
                  })
            //   }
          }else{
            this.setState({
              btnText:"ENVIAR",
                alert: true,
                alertType:"uk-alert-danger",
                alertMessage:"No se ha completado tu solicitud.",
                error: "uk-alert-danger",
                feedBackInput: {
                  email: "",
                },
              })
          }
        //   this.validateInput(data["flexformdata"])
        }
       
    }


    render() {
       
        return (
            <>
            <div className={this.state.alertType}  data-uk-alert>
                    <a className="uk-alert-close" data-uk-close></a>
                    <p className="uk-text-small">{this.state.alertMessage}</p>
                </div>
            <form id="suscripcionForm" onSubmit={this.SendEntries} className=" uk-flex uk-flex-middle uk-flex-center uk-flex-left@m">
                
                <div className="uk-position-relative">
                    <input type="email" name="email" onChange={(e) => this.setState({feedBackInput: {email: e.target.value}})} placeholder="Correo electrónico" />
                    <button type="submit" className="uk-position-absolute btn btn--primary btn--suscribe">{this.state.btnText}</button>
                </div>
            </form>
            </>
        );
    }

}

export default Suscripcion