import React from "react"
// import { Link } from "gatsby"
import {siteUrl} from "../../../config/app.js"

const Swichers = items => {
  const hiddenNav = () => {
    window.UIkit.offcanvas("#offcanvas-nav").hide()
  }


  return (
    <div className="swicher-content uk-container uk-container-expand uk-padding-small">
      <div className="uk-position-relative uk-visible-toggle" tabIndex="-1">
        <ul className="uk-list uk-padding-remove uk-child-width-1-1 uk-child-width-1-6@m uk-grid uk-grid-collapse">
          {items.items.map(item => (
            <li key={item.node.id} className="uk-margin-remove">
              <div className=" uk-padding-remove item-vehiculos" >
                <a onClick={hiddenNav} className="uk-padding-remove" href={`${siteUrl+item?.node?.slug}`}>
                  <div className=" uk-text-center item-swich">
                    <div className="">
                      <div
                        className="img-swich uk-height-medium  uk-height-max-small uk-flex uk-background-contain"
                        data-src={ item.node.featuredImage?.sourceUrl  }
                        data-srcset={item.node.featuredImage?.sourceUrl}
                        data-sizes="(min-width: 120px) 200px, 50vw"
                        data-uk-img="true"
                      />
                    </div>

                    <div className="item-text uk-padding-remove uk-width-expand">
                      <p className="uk-card-title uk-text-center uk-margin-remove">
                        {item.node.title} 
                      </p>
                      <h5 className="uk-margin-remove-top uk-text-center">

                        {item.node.mostrarPalabraDesde === true && "DESDE" }
                        {item.node.versiones !== null &&
                          ` US$${new Intl.NumberFormat().format(
                            item.node.versiones.edges[0].node.precio_version
                          )}`
                        }
                      </h5>
                    </div>
                  </div>
                </a>
              </div>
            </li>
          ))}
        </ul>
        {/* <hr className="uk-divider-small uk-visible@s" /> */}
        {/* <div className="uk-container uk-container-expand uk-margin-bottom uk-visible@s">
          <div className="uk-flex uk-flex-between">

              <Link
                className="uk-button uk-button-default uk-button-large "
                to="solicitud-cotizacion"
              >Cotizar</Link>

              <button  className="uk-button uk-button-default uk-button-large " data-uk-toggle="target: #drop-menu-content" type="button">Cerrar</button>

          </div>
        </div> */}
      </div>
    </div>
  )
}

export default Swichers
