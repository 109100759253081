/* eslint-disable */
import React, {useEffect,useState} from "react";
import { Link } from "gatsby";

import MenuItem from "./MenuItem.js";
import ChevListMobile from "../ChevroletList/ChevListMobile";

import logoSuzuki from "../../assets/img/logo.svg";
import Now from "../../assets/svg/nav-search-chat-now.svg";
import SearchIcon from "../../assets/svg/nav-search-contact.svg";
import Search from "../../templates/search";
import ChevroletList from "../ChevroletList/";
import Faq from "../../assets/svg/nav-search-faq.svg";
import { location } from "@reach/router";
import { FaBars } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

//import { OffCanvas } from "../ui";
//import Calculators from "../calculators";

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      bgmenu : ""
    }
  }

  componentDidMount(prevProps, prevState) {
    window.onscroll = () => {
      let bgmenu = ""
      window.pageYOffset > 20 ? bgmenu = "" : bgmenu = "uk-navbar-transparent";
      this.setState({ bgmenu: bgmenu})
     }
    //  console.log(document.body.classList.contains('hero-banner'));
  }

  render() {
    const menuItems = this.props.menuItems.edges;
    const hasHeroBanner = this.props.hasHeroBanner ? 'nav-light' : 'nav-dark';
    // ${this.props.insideSectionPath.includes(location.pathname) ? 'top: 0': 'top: 200'}
    // ${this.props.insideSectionPath.includes(location.pathname) ? 'inner--navbar': null}

    // console.log('INCLUDE->',props.body.includes('class="hero-banner"'));
    // console.log('TEXTO ->', this.props.heroBannerExist)

    return (
      <>
        <div
         className="uk-position-relative"
          data-uk-sticky
        >

          <nav
            id="top-menu"
            className={`${hasHeroBanner} uk-padding-top uk-flex uk-flex-between ${this.state.bgmenu}`}
            data-uk-navbar="true"
          >
            <a href="/" className="uk-navbar-item uk-logo">
             <img src="https://back.suzuki.com.do/wp-content/uploads/2022/07/SUZUKI_horizontal_783x153px.png" alt="Suzuki Motors República Dominicana" height="50" alt="Suzuki" width="261"/> 
           </a>

            <ul className="uk-navbar-nav  uk-visible@m uk-padding uk-padding-remove-vertical uk-padding-remove-left uk-flex-middle">
              {menuItems.map((item) => (
                <MenuItem key={item.node.id} items={item}  />
              ))}
            </ul>

            <button
              type="button"
              className="uk-hidden@m uk-button mobile-hambuger"
              data-uk-toggle="target: #offcanvas-nav"
            >
              <FaBars />
            </button>
          </nav>

          <div
            id="offcanvas-nav"
            data-uk-offcanvas="overlay: true;flip: true;"
            className="chevrolet-bg-white"
          >
            <div className="uk-offcanvas-bar">
              <a href="#" className="uk-offcanvas-close" data-uk-close />
              <a href="/test-drive" className="btn btn--primary">
                <span className="navbar-icon uk-padding-remove-left">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27.053"
                    height="27.056"
                    viewBox="0 0 27.053 27.056"
                  >

                    <g
                      id="Group_5"
                      data-name="Group 5"
                      transform="translate(-3360.452 -370.202)"
                    >

                      <path
                        id="Path_23"
                        data-name="Path 23"
                        d="M3373.132,370.2h1.691c.076.015.153.033.23.046.737.119,1.487.184,2.209.362a13.523,13.523,0,1,1-16.609,15.422c-.086-.483-.135-.973-.2-1.459v-1.691c.086-.6.151-1.2.262-1.795a13.516,13.516,0,0,1,10.906-10.677C3372.122,370.328,3372.628,370.271,3373.132,370.2Zm11.191,13.532a10.345,10.345,0,1,0-10.34,10.338A10.374,10.374,0,0,0,3384.323,383.734Z"
                        transform="translate(0 0)"
                      />
                      <path
                        id="Path_24"
                        data-name="Path 24"
                        d="M3468.084,526.082c-.115.728-.234,1.455-.343,2.183-.028.188-.119.255-.307.277a6.882,6.882,0,0,0-3.153,1.107,5.9,5.9,0,0,0-2.427,3.791c-.064.292-.091.591-.156.883a.316.316,0,0,1-.171.209,8.638,8.638,0,0,1-4.537.012c-.148-.038-.167-.121-.182-.242a7.729,7.729,0,0,0-.585-2.237,5.8,5.8,0,0,0-4.331-3.388c-.292-.063-.592-.091-.882-.156a.312.312,0,0,1-.2-.178,8.578,8.578,0,0,1,.428-5.751.506.506,0,0,1,.218-.21,14.776,14.776,0,0,1,4.847-1.632,18.73,18.73,0,0,1,5.549-.056,14.851,14.851,0,0,1,5.213,1.687.625.625,0,0,1,.25.28,8.692,8.692,0,0,1,.706,3.412Zm-11.206-.016a2.38,2.38,0,1,0,2.4-2.365A2.38,2.38,0,0,0,3456.877,526.067Z"
                        transform="translate(-85.28 -142.355)"
                      />
                      <path
                        id="Path_25"
                        data-name="Path 25"
                        d="M3601.749,610.679a.8.8,0,1,1-.817.76A.788.788,0,0,1,3601.749,610.679Z"
                        transform="translate(-227.749 -227.747)"
                      />
                    </g>
                  </svg>
                </span>
                Test Drive
              </a>

              <ul
                className="uk-padding uk-padding-remove-horizontal uk-nav-parent-icon uk-nav-default"
                data-uk-nav
              >
                <li className="uk-parent">
                  <a href="#">
                    Elige tu Modelo
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8.354"
                      height="6.464"
                      viewBox="0 0 8.354 6.464"
                    >

                      <path
                        id="Path_541"
                        data-name="Path 541"
                        d="M1181.467,1094.883c-.307-.34-.308-.432.017-.793,1.006-1.118,2.013-2.234,3.044-3.377l-2.081-2.318c-.347-.386-.708-.759-1.034-1.161a.547.547,0,0,1-.11-.462.557.557,0,0,1,.424-.224c.683-.023,1.368-.03,2.051,0a.877.877,0,0,1,.534.279c1.14,1.234,2.263,2.484,3.392,3.729v.3l-3.65,4.021Z"
                        transform="translate(1094.883 -1181.238) rotate(90)"
                      />
                    </svg>
                  </a>
                   {/* aqui va el menu mobile listado de carros */}
                  <ChevListMobile/>
                  {/* aqui va el menu mobile listado de carros */}
	    	</li>
                <li className="uk-nav-divider" />
                <li>
                  <a href="/cotizar/">Cotizar</a>
                </li>
                <li className="uk-nav-divider" />
                <li>
                  <a href="/calcula-tus-cuotas/">Calcular tus Cuotas</a>
                </li>
                <li className="uk-nav-divider" />
                <li>
                  <a href="/salon-virtual">Salón Virtual</a>
                </li>
                <li className="uk-nav-divider" />
                <li className="uk-parent">
                  <a href="#">
                    Ver más
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8.354"
                      height="6.464"
                      viewBox="0 0 8.354 6.464"
                    >

                      <path
                        id="Path_541"
                        data-name="Path 541"
                        d="M1181.467,1094.883c-.307-.34-.308-.432.017-.793,1.006-1.118,2.013-2.234,3.044-3.377l-2.081-2.318c-.347-.386-.708-.759-1.034-1.161a.547.547,0,0,1-.11-.462.557.557,0,0,1,.424-.224c.683-.023,1.368-.03,2.051,0a.877.877,0,0,1,.534.279c1.14,1.234,2.263,2.484,3.392,3.729v.3l-3.65,4.021Z"
                        transform="translate(1094.883 -1181.238) rotate(90)"
                      />
                    </svg>
                  </a>
                  <ul className="uk-nav-sub ">
                    <li className="uk-margin-small">
                      <a href="/servicios/">Servicios</a>
                    </li>
                    <li className="uk-margin-small">
                      <a href="/contacto/">Contacto</a>
                    </li>
                    <li className="uk-margin-small">
                      <a href="/acerca-de-suzuki">Acerca de Suzuki</a>
                    </li>
                    <li className="uk-margin-small">
                      <a href="/puntos-de-ventas">Puntos de Venta</a>
                    </li>
                    <li className="uk-margin-small">
                      <a href="/cotizar-piezas">Cotizar Piezas</a>
                    </li>
                     
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Header;
